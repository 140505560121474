import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// function loadLocaleMessages() {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const messages = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   return messages;
// }

// export default new VueI18n({
//   locale: window.localStorage.getItem("locale") || "en",
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
//   messages: loadLocaleMessages(),
// });

const i18n = new VueI18n({
  locale: window.localStorage.getItem("locale") || navigator.language || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
});

// const loadedLanguages = []

// function setI18nLanguage (lang) {
//   i18n.locale = lang
//   // axios.defaults.headers.common['Accept-Language'] = lang
//   // document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

export async function loadLanguageAsync() {
  const langs = await (
    await fetch(process.env.BASE_URL + "locales/list.json")
  ).json();
  const promises = [];

  for (let lang of langs) {
    promises.push(
      fetch(process.env.BASE_URL + `locales/${lang}.json`)
        .then((res) => res.json())
        .then((json) => {
          i18n.mergeLocaleMessage(lang, json);
        })
        .catch((e) => {
          console.error(`Error while loading lang ${lang} -`, e);
          return e;
        })
    );
  }
  // Load all the sub indication translation for the signature verification
  promises.push(
    fetch(process.env.BASE_URL + `locales/verifySubIndication.json`)
      .then((res) => res.json())
      .then((json) => {
        for (let l of Object.keys(json)) {
          i18n.mergeLocaleMessage(l, { subIndicationEnum: json[l] });
        }
      })
      .catch((e) => {
        console.error(`Error while loading subIndications - `, e);
        return e;
      })
  );

  await Promise.all(promises);
}

export default i18n;
