import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
import { formatDocumentFilters } from "@/helpers";

export const classService = {
  fetchDocuments: fetchDocumentHistory,
  fetchDocumentProperties,
  fetchDocumentHistory,
  fetchLastUploadedProgressive,
  doEInvoiceAction,
};

const classEndpoint = KUrls.CLASS_ENDPOINT || "/class";

function handleResponse(response) {
  const data = response && response.data;
  if (!response.status && response.status != 200) {
    if (response.status === 401) {
      console.log("unauthorized");
      // unauthorized
    }
    const error = (data && data.message) || response.statusText;
    console.log(error);
    return Promise.reject(error);
  }

  return data;
}

async function fetchDocumentProperties(
  company,
  classId,
  searchable = false,
  uploadable = false
) {
  console.log(classId);
  try {
    const authorization = authHeader();
    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${classEndpoint}/properties/${company}`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          classId: classId,
          searchable,
          uploadable,
        },
      }
    );
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 *
 * @param {*} classId
 * @param {*} filterList
 * @param {*} page
 * @param {*} perPage
 */
async function fetchDocumentHistory(
  companySchema,
  classId,
  filterList,
  page,
  perPage,
  isHistory
) {
  try {
    const formattedFilterList = formatDocumentFilters(filterList);
    const authorization = authHeader();
    // TODO: change with GET!
    const response = await axios.post(
      `${KUrls.BASE_REST_URL}${classEndpoint}/${
        isHistory ? "documenthistory" : "documents"
      }/${companySchema}`,
      {
        userSchema: companySchema,
        classId: classId,
        filters: formattedFilterList,
        page: page,
        perPage: perPage,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function fetchLastUploadedProgressive(company, className) {
  try {
    const authorization = authHeader();
    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${classEndpoint}/progressive/${company}`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          className,
        },
      }
    );
    const data = await handleResponse(response);
    return data.progressive;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function doEInvoiceAction(companySchema, payload) {
  const authorization = authHeader();
  const response = await axios.get(
    `${KUrls.BASE_SERVLET_URL}/protected/einvoice`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
      params: {
        token: authorization,
        company: companySchema,
        ...payload,
      },
    }
  );
  switch (response.status) {
    case 200: {
      return { ...response.data };
    }
    case 201: {
      const act =
        payload.action === "SHOW" || payload.action === "EXPORT_AS_PDF"
          ? "display"
          : "download";
      let url = `${KUrls.DOMAIN}${KUrls.BASE_SERVLET_URL}/protected/${act}`;
      let params = new URLSearchParams({
        token: authorization,
        company: companySchema,
        ...response.data,
      });
      if (payload.action === "SHOW" || payload.action === "EXPORT_AS_PDF") {
        return {
          url: `${url}?${params.toString()}`,
          fileName: response.data["filenameOutput"],
          mimeType: response.data["mimeType"],
          action: "SHOW",
        };
      } else {
        window.open(`${url}?${params.toString()}`, "_blank");
        return {};
      }
    }
    case 204:
      break;
  }
}
