import documents from "./documents";
import properties from "./properties";

export default {
  namespaced: true,
  modules: {
    documents: documents,
    properties: properties,
  },
  state: {
    classId: null,
    test: "testing",
  },
  getters: {
    // isLoading: (state) => {
    //   return state.documents.isLoading
    // }
  },
  mutations: {
    setClassId(state, classId) {
      state.classId = classId;
    },
  },
  actions: {
    changeClass({ commit }, payload) {
      commit("setClassId", payload);
      commit("resetDocuments");
      commit("resetProperties");
    },
  },
};
