import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import users from "./modules/users";
import menu from "./modules/menu";
import clazz from "./modules/class";
import alert from "./modules/alert";
import tabs from "./modules/tabs";

import { authService } from "@/services";
import { deepCopy } from "@/helpers";

Vue.use(Vuex);

const usersPersistedState = createPersistedState({
  key: "user_state",
  paths: ["users", "datePattern"],
});

const store = new Vuex.Store({
  plugins: [usersPersistedState],
  modules: {
    users: users,
    menu: menu,
    class: clazz,
    alert: alert,
    tabs: tabs,
  },
  state: {
    datePattern: "dd/MM/y",
  },
  getters: {
    getDatePattern: (state) => {
      return state.datePattern;
    },
  },
  mutations: {
    saveDatePattern(state, pattern) {
      state.datePattern = pattern;
    },
  },
  actions: {
    logout({ commit }) {
      authService.logout();
      commit("logout");
    },
  },
});

export default store;

const initialStateCopy = deepCopy(store.state);

export function resetState() {
  authService.logout();
  localStorage.removeItem("user_state");
  store.replaceState(deepCopy(initialStateCopy));

  // let state = store.state;
  // let newState = {};

  // Object.keys(state).forEach((key) => {
  //   newState[key] = null; // or = initialState[key]
  // });

  // store.replaceState(newState);
}
