const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null,
  },
  getters: {},
  mutations: {
    success(state, message) {
      state.type = "is-success";
      state.message = message;
    },
    error(state, message) {
      state.type = "is-danger";
      state.message = message;
    },
    clear(state) {
      state.type = null;
      state.message = null;
    },
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, message) {
      commit("error", message);
    },
    clear({ commit }) {
      commit("clear");
    },
  },
};

export default alert;
