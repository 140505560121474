// const KValueTypes = [
//   "STRING",
//   "alfanumerico",
//   "NUMBER_INTEGER",
//   "NUMBER_LONG",
//   "numerico",
//   "NUMBER_BIG_INTEGER",
//   "NUMBER_BIG_DECIMAL",
//   "NUMBER_FLOAT",
//   "NUMBER_DOUBLE",
//   "BOOLEAN",
//   "DATE",
//   "TIME",
//   "DATETIME",
//   "DATE_LEGACY",
//   "data",
// ];

const ConcatOperators = Object.freeze(["AND", "OR"]);

const Operators = Object.freeze({
  EQUALS: {
    key: "E",
    symbol: "=",
    icon: "&#61;",
  },
  NOT_EQUALS: {
    key: "NE",
    symbol: "!=",
    icon: "&ne;",
  },
  LESS_THAN: {
    key: "LT",
    symbol: "<",
    icon: "&lt;",
  },
  LESS_EQUAL_THAN: {
    key: "LET",
    symbol: "<=",
    icon: "&leq;",
  },
  GREATER_THAN: {
    key: "GT",
    symbol: ">",
    icon: "&gt;",
  },
  GREATER_EQUAL_THAN: {
    key: "GET",
    symbol: ">=",
    icon: "&geq;",
  },
  LIKE: {
    key: "LIKE",
    symbol: "LIKE",
    icon: "~",
  },
  NOT_LIKE: {
    key: "NOT_LIKE",
    symbol: "NOT LIKE",
    icon: "!~",
  },
  BETWEEN: {
    key: "BETWEEN",
    symbol: "[a,b]",
    icon: "[a,b]",
  },
  IN: {
    key: "IN",
    symbol: "in",
    icon: "{;;;}",
  },
});

const comparisonOperatorGroup1 = [
  {
    key: "E",
    symbol: "=",
    icon: "&#61;",
  },
  {
    key: "NE",
    symbol: "!=",
    icon: "&ne;",
  },
  {
    key: "LT",
    symbol: "<",
    icon: "&lt;",
  },
  {
    key: "LET",
    symbol: "<=",
    icon: "&leq;",
  },
  {
    key: "GT",
    symbol: ">",
    icon: "&gt;",
  },
  {
    key: "GET",
    symbol: ">=",
    icon: "&geq;",
  },
  {
    key: "LIKE",
    symbol: "LIKE",
    icon: "~",
  },
];

const baseOperators = Object.freeze([
  Operators.EQUALS,
  Operators.NOT_EQUALS,
  Operators.GREATER_THAN,
  Operators.GREATER_EQUAL_THAN,
  Operators.LESS_THAN,
  Operators.LESS_EQUAL_THAN,
]);

// const comparisonOperatorGroup2 = [
//   ...comparisonOperatorGroup1,
//   {
//     key: "LIKE",
//     symbol: "LIKE",
//   },
// ];

const KValueTypes = {
  STRING: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  alfanumerico: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  NUMBER_INTEGER: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  NUMBER_LONG: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  numerico: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  NUMBER_BIG_INTEGER: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  NUMBER_BIG_DECIMAL: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  NUMBER_FLOAT: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  NUMBER_DOUBLE: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
  },
  BOOLEAN: {
    operators: [Operators.EQUALS],
  },
  DATE: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  TIME: {
    operators: [...baseOperators],
  },
  DATETIME: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  DATE_LEGACY: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  DATE_LEGACY_SQL: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  DATETIME_LEGACY: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  DATETIME_LEGACY_SQL: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  data: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
  OFFSET_TIME: {
    operators: [...baseOperators],
  },
  OFFSET_DATETIME: {
    operators: [...baseOperators, Operators.BETWEEN],
  },
};

const KFilterSpecificOperators = {
  class_id: {
    operators: [Operators.IN],
    defaultOperator: Operators.IN,
  },
  stato_conservazione: {
    operators: [Operators.IN],
    defaultOperator: Operators.IN,
  },
  denominazione: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
    defaultOperator: Operators.LIKE,
  },
  cognome: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
    defaultOperator: Operators.LIKE,
  },
  nome: {
    operators: [...comparisonOperatorGroup1, Operators.IN],
    defaultOperator: Operators.LIKE,
  },
};

function getOperatorsList() {
  return Object.values(Operators);
}

const checkType = {
  isDate(type) {
    return (
      type === "data" ||
      type === "DATE" ||
      type === "DATE_LEGACY" ||
      type === "DATE_LEGACY_SQL"
    );
  },
  isDateTime(type) {
    return (
      type === "DATETIME" ||
      type === "DATETIME_LEGACY" ||
      type === "DATETIME_LEGACY_SQL" ||
      type === "OFFSET_DATETIME"
    );
  },
  isTime(type) {
    return type === "TIME" || type === "TIME_LEGACY";
  },
  isBoolean(type) {
    return type === "BOOLEAN";
  },
};
export {
  Operators,
  KValueTypes,
  KFilterSpecificOperators,
  ConcatOperators,
  getOperatorsList,
  checkType,
};
