import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
// import { prepareEinvoiceFiltersToSend } from "@/helpers";

export const volumeService = {
  getVolumes,
  getWorkingVolumes,
  getSignedVolumes,
  getWorkingVolumeDocuments,
  getSignedVolumeDocuments,
  getVolumeDocuments,
};

const volumeEndpoint = KUrls.VOLUME_ENDPOINT || "/volumes";

async function getVolumes(
  companyName,
  elementsNumber,
  pageNumber,
  areSignedVolumes
) {
  try {
    const authorization = authHeader();

    const volumeType = areSignedVolumes ? "signed" : "working";

    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${volumeEndpoint}/${volumeType}/${companyName}`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          elementsNumber: elementsNumber,
          pageNumber: pageNumber,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function getWorkingVolumes(companyName, elementsNumber, pageNumber) {
  return await getVolumes(companyName, elementsNumber, pageNumber, false);
}

async function getSignedVolumes(companyName, elementsNumber, pageNumber) {
  return await getVolumes(companyName, elementsNumber, pageNumber, true);
}

async function getSignedVolumeDocuments(
  companyName,
  volumeId,
  elementsNumber,
  pageNumber
) {
  return await getVolumeDocuments(
    companyName,
    volumeId,
    elementsNumber,
    pageNumber,
    true
  );
}

async function getWorkingVolumeDocuments(
  companyName,
  volumeId,
  elementsNumber,
  pageNumber
) {
  return await getVolumeDocuments(
    companyName,
    volumeId,
    elementsNumber,
    pageNumber,
    true
  );
}

async function getVolumeDocuments(
  companyName,
  volumeId,
  elementsNumber,
  pageNumber,
  areSignedVolumes
) {
  try {
    const authorization = authHeader();

    const volumeType = areSignedVolumes ? "signed" : "working";

    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${volumeEndpoint}/${volumeType}/${companyName}/${volumeId}/documents`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          elementsNumber: elementsNumber,
          pageNumber: pageNumber,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
