import { formatISO, format, parse } from "date-fns";

export function toStringISODate(date) {
  return formatISO(date, {
    representation: "date",
  });
}

export function toStringISODateTime(date) {
  return formatISO(date, {
    representation: "complete",
  });
}

/**
 *
 * @param {Date} date The date to parse
 * @param {String} pattern The pattern to be used when parsing
 * @param {import("date-fns").Locale} locale If null the browser default will be used
 * @returns A string containing the parsed date.
 */
export function formatDate(date, pattern = "dd/MM/y", locale) {
  if (!locale) locale = navigator.language;
  const options = { weekStartsOn: 1, locale };

  return format(date, pattern, new Date(), options);
}

export function parseDate(date, pattern = "d/M/y", locale) {
  if (!locale) locale = navigator.language;
  const options = { weekStartsOn: 1, locale };

  return parse(date, pattern, new Date(), options);
}
