<template>
  <section>
    <b-loading :active="qrStatusWaiting"></b-loading>
    <div class="container">
      <div class="block">
        <!-- <b-image :src="qr" alt="QR" ratio="1by1">
          <template #placeholder>
            <b-skeleton
              :active="loadingQr"
              :width="qrSizePx"
              :height="qrSizePx"
            ></b-skeleton>
          </template>
        </b-image> -->

        <figure class="image is-square" v-if="!error" @click="openMobileApp">
          <img v-show="!loadingQr" :src="qr" />
          <b-loading :active="loadingQr" :is-full-page="false"></b-loading>
        </figure>

        <svg
          v-else
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
          class="has-background-grey-lighter"
        >
          <line
            x1="10"
            y1="10"
            x2="90"
            y2="90"
            style="stroke: #000; stroke-width: 7"
          />
          <line
            x1="90"
            y1="10"
            x2="10"
            y2="90"
            style="stroke: #000; stroke-width: 7"
          />
        </svg>

        <!-- <b-skeleton
          :active="loadingQr"
          :width="qrSizePx"
          :height="qrSizePx"
        ></b-skeleton> -->
      </div>
      <div
        class="box block is-size-4 has-text-weight-bold has-background-grey-lighter"
      >
        <p
          class="has-text-centered"
          v-for="(txt, index) in msg.split('\n')"
          :key="index"
        >
          {{ txt }}
        </p>
      </div>
      <div class="block">
        <div
          class="is-clickable"
          style="display: inline-block"
          @click="reloadClick"
        >
          <b-icon icon="refresh" size="is-large"></b-icon>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { authService, loginResponse } from "../../services";
import { uaMixin } from "@/mixins/user-agent";
import { saveAuthToken } from "../../helpers/authHeader";
export default {
  mixins: [uaMixin],
  data() {
    return {
      loadingQr: false,
      qrStatus: "",
      qr: null,
      matchcode: "",
      msg: "",
      qrSize: 190,
      qrMime: "image/svg+xml",
      qrLink: "",
      spinning: false,
      sse: null,
      error: false,
    };
  },
  computed: {
    qrSizePx() {
      return this.qrSize + "px";
    },
    redirect() {
      return this.$route.query.redirect || "/dashboard";
    },
    qrStatusWaiting() {
      return this.qrStatus === "Waiting";
    },
  },
  methods: {
    async loadQr() {
      try {
        this.loadingQr = true;
        const res = await authService.getLoginQr(
          window.location.href,
          this.qrSize,
          this.qrMime
        );
        this.qr = `data:${this.qrMime};base64,${res.qr}`;
        this.matchcode = res["matchcode"];
        this.msg = res["msg"];
        this.qrLink = res["qrClickLink"];
        this.loadingQr = false;

        this.sse = authService.listenQrStatus(res["qrId"]);

        this.sse.addEventListener("status", (e) => {
          console.log("Status changed!", e);
          console.log(e.data);
          const data = JSON.parse(e.data);
          const status = data["status"];
          this.qrStatus = status;
          console.log(status);
          switch (status) {
            case "Waiting": {
              break;
            }
            case "Completed": {
              const { username } = data;
              saveAuthToken(data[loginResponse.token]);
              this.$store.commit("users/loginSuccess", {
                user: username,
                schemas: data[loginResponse.userSchemas],
                merchant: data[loginResponse.merchant],
                subNickname: data[loginResponse.subNickname],
              });
              this.$router.push(this.redirect);
              break;
            }
            case "Timeout":
            case "Error": {
              this.error = true;
              break;
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    openMobileApp() {
      const qrClickLink = this.qrLink;
      if (this.isIOS) window.open(qrClickLink);
      else {
        var win = window.open(
          qrClickLink,
          "1366002941508",
          "width=500,height=200,left=375,top=330"
        );
        setTimeout(function () {
          win.close();
        }, 3000);
      }
    },
    reloadClick() {
      setTimeout(() => (this.spinning = false), 400);
      this.error = false;
      this.spinning = true;
      this.resetQrInfo();
      this.loadQr();
    },
    resetQrInfo() {
      this.qr = null;
      this.msg = "";
      this.qrSize = 190;
      this.qrMime = "image/svg+xml";
      this.qrLink = "";
    },
  },
  mounted() {
    this.loadQr();
  },
  beforeDestroy() {
    if (this.sse) {
      this.sse.close();
      this.sse = "";
    }
  },
};
</script>

<style></style>
