import { classService } from "@/services";

export default {
  // namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isError: false,
  },
  getters: {
    getAllProperties(state) {
      return state.data;
    },
    getSearchableProperties(state) {
      return state.data.filter((prop) => {
        return prop.searchable || prop.name.toLowerCase() === "id";
      });
    },
    getUploadableProperties(state) {
      return state.data.filter((prop) => {
        return prop.uploadable;
      });
    },
  },
  mutations: {
    setProperties(state, filtersData) {
      state.data = filtersData;
    },
    propertyRequestInit(state) {
      state.isLoading = true;
    },
    propertyRequestSuccess(state, filtersData) {
      const formattedFilters = filtersData.map((f) => {
        let newF = { ...f };
        if (newF.type === "data") {
          newF.valueRange = [];
        }
        f.value = null;
        return newF;
      });
      state.data = formattedFilters;
      state.isError = false;
      state.isLoading = false;
    },
    propertyRequestFailure(state) {
      state.isError = true;
      state.data = {};
    },
    resetProperties(state) {
      state.data = [];
      state.isLoading = false;
      state.isError = false;
    },
  },
  actions: {
    fetchProperties({ commit, state, rootState }, { companyName, classId }) {
      // If the stored classId is the same as the requested one do not fetch, this prevent fetching the same thing multiple times
      if (rootState.class.classId !== classId || state.data.length === 0) {
        console.log("getting properties");
        commit("propertyRequestInit");

        classService.fetchDocumentProperties(companyName, classId).then(
          (documents) => {
            commit("setClassId", classId);
            commit("propertyRequestSuccess", documents);
          },
          (error) => {
            commit("propertyRequestFailure", error);
            //   dispatch("alert/error", error, { root: true });
          }
        );
      }
    },
  },
};
