import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
import { formatFilters } from "@/helpers";

export const searchService = {
  fetchSearchFilters,
  searchDocuments,
  fetchDocumentDetails,
};

const searchEndpoint = KUrls.SEARCH_ENDPOINT || "/search";

async function fetchSearchFilters(company) {
  try {
    const authorization = authHeader();
    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${searchEndpoint}/${company}/filters`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function searchDocuments(
  company,
  queryFilterGroup,
  pageNumber,
  perPage,
  searchWorking = false,
  searchHistory = false,
  sortingPriority,
  onlyCommonProps = true
) {
  try {
    const authorization = authHeader();

    const formattedQFG = {
      operator: queryFilterGroup.operator,
      filters: null,
    };
    formattedQFG.filters = formatFilters(queryFilterGroup.filters);

    console.log(formattedQFG);

    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${searchEndpoint}/${company}/documents`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          page: pageNumber,
          perPage,
          searchWorking,
          searchHistory,
          queryFilterGroup: formattedQFG,
          commonProps: onlyCommonProps,
          orderBy: JSON.stringify(sortingPriority),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function fetchDocumentDetails(company, classId, documentId) {
  try {
    const authorization = authHeader();

    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${searchEndpoint}/${company}/documents/details`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
        params: {
          id: documentId,
          classId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
