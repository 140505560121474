import { classService } from "@/services";

export default {
  // namespaced: true,
  state: {
    data: [],
    isLoading: false,
    isError: false,
  },
  getters: {
    getDocuments(state) {
      return state.data.map((doc) => {
        return doc.properties;
      });
    },
  },
  mutations: {
    setDocuments(state, menuData) {
      state.data = menuData;
    },
    docRequestInit(state) {
      state.isLoading = true;
    },
    docRequestSuccess(state, documents) {
      state.data = documents;
      state.isError = false;
      state.isLoading = false;
    },
    docRequestFailure(state) {
      state.isError = true;
      state.data = {};
    },
    resetDocuments(state) {
      state.data = [];
      state.isLoading = false;
      state.isError = false;
    },
  },
  actions: {
    fetchDocuments({ commit }, payload) {
      commit("docRequestInit");
      const classId = payload.classId;
      const companySchema = payload.companySchema;
      const propertyList = payload.propertyList;
      classService
        .fetchDocumentHistory(companySchema, classId, propertyList)
        .then(
          (documents) => {
            commit("docRequestSuccess", documents);
          },
          (error) => {
            commit("docRequestFailure", error);
            //   dispatch("alert/error", error, { root: true });
          }
        );
    },
    fetchDocumentHistory() {},
  },
};
