const KCommonEInvoiceFilters = [
  // {
  //   name: "CLASS_NAME",
  //   type: "STRING",
  //   filterValue: {
  //   value1: ""
  // },
  // value: null,
  //   operator: "E",
  // },
  {
    name: "ET_DOC_ID",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "PROGRESSIVE",
    type: "NUMBER_LONG",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "FISCAL_YEAR",
    type: "NUMBER_INTEGER",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "INVOICE_NUMBER",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "INVOICE_DATE",
    type: "DATE_LEGACY",
    // filterValue: {
    //   value1: null,
    // },
    value: null,
    operator: "E",
  },
  {
    name: "NET_AMOUNT",
    type: "NUMBER_BIG_DECIMAL",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "VAT_AMOUNT",
    type: "NUMBER_BIG_DECIMAL",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "TOTAL_AMOUNT",
    type: "NUMBER_BIG_DECIMAL",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "DOC_TYPE",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "RELATED_INVOICES_NUMBERS",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "FILE_NAME",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "SDI_ID",
    type: "NUMBER_LONG",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  // {
  //   name: "TXRX_DENOMINAZIONE",
  //   type: "STRING",
  //   filterValue: {
  //     value1: "",
  //   },
  // value: null,
  //   operator: "E",
  // },
  // {
  //   name: "TXRX_PARTITA_IVA",
  //   type: "STRING",
  //   filterValue: {
  //     value1: "",
  //   },
  // value: null,
  //   operator: "E",
  // },
  {
    name: "TIMING",
    type: "DATETIME_LEGACY",
    // filterValue: {
    //   value1: null,
    // },
    value: null,
    operator: "E",
  },
  {
    name: "MSG_TYPE",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
];

const KActiveEInvoiceFilters = [
  {
    name: "RX_DENOMINAZIONE",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "LIKE",
  },
  {
    name: "RX_PARTITA_IVA",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
];

const KPassiveEInvoiceFilters = [
  {
    name: "TX_DENOMINAZIONE",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "LIKE",
  },
  {
    name: "TX_PARTITA_IVA",
    type: "STRING",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
  {
    name: "IS_DIRECT_PROCUREMENT",
    type: "BOOLEAN",
    // filterValue: {
    //   value1: "",
    // },
    value: null,
    operator: "E",
  },
];

function getActiveEInvoiceFilters() {
  const commonFilters = JSON.parse(JSON.stringify(KCommonEInvoiceFilters));
  const activeFilters = JSON.parse(JSON.stringify(KActiveEInvoiceFilters));
  const passiveFilters = JSON.parse(JSON.stringify(KPassiveEInvoiceFilters));
  return commonFilters.concat(activeFilters).concat(passiveFilters);
}

function getPassiveEInvoiceFilters() {
  const commonFilters = JSON.parse(JSON.stringify(KCommonEInvoiceFilters));
  const activeFilters = JSON.parse(JSON.stringify(KActiveEInvoiceFilters));
  const passiveFilters = JSON.parse(JSON.stringify(KPassiveEInvoiceFilters));
  return commonFilters.concat(activeFilters).concat(passiveFilters);
}

function getAllEInvoiceFilters() {
  const commonFilters = JSON.parse(JSON.stringify(KCommonEInvoiceFilters));
  const activeFilters = JSON.parse(JSON.stringify(KActiveEInvoiceFilters));
  const passiveFilters = JSON.parse(JSON.stringify(KPassiveEInvoiceFilters));
  return commonFilters.concat(activeFilters).concat(passiveFilters);
}

export const KEInvoiceFilters = {
  getAllEInvoiceFilters,
  getActiveEInvoiceFilters,
  getPassiveEInvoiceFilters,
};
