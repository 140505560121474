<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered is-mobile">
          <div
            class="column is-5-tablet is-4-desktop is-3-widescreen is-8-mobile"
          >
            <b-image :src="logoDir" alt="Logo" class="block"></b-image>
            <!-- <h3 class="title">Login</h3>
            <div class="is-divider"></div>
            <h2 class="subtitle">{{ $t("login.page.subtitle") }}</h2> -->

            <div class="block">
              <login-qr v-if="$config.loginQR"></login-qr>

              <LoginForm
                v-else
                :redirection="redirect"
                :loading="getStatus.loggingIn"
                @login="onLogin"
              >
                <template #error>
                  <b-notification
                    type="is-danger"
                    aria-close-label="Close notification"
                    role="alert"
                    v-if="getStatus.error"
                  >
                    {{ $t("login.error.login_failed") }}
                  </b-notification>
                </template>
              </LoginForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoginForm from "@/components/login/LoginForm.vue";
import LoginQr from "../components/login/LoginQr.vue";

const mode = process.env.VUE_APP_MODE;

export default {
  name: "Login",
  components: { LoginForm, LoginQr },
  data() {
    return {
      redirect: this.$route.query.redirect || "/dashboard",
    };
  },
  computed: {
    ...mapGetters("users", ["getStatus"]),
    logoDir() {
      if (mode === "eurotext") {
        return require("@/assets/eurotext_logo_optimized.svg");
      } else if (mode === "mobysign") {
        return require("@/assets/mobysign_logo_optimized.svg");
      } else {
        return require("@/assets/logo_login.svg");
      }
    },
  },
  // watch: {
  //   getStatus: function(){
  //     if(this.getStatus)
  //   }
  // }
  methods: {
    ...mapActions("users", ["login"]),
    onLogin(username, password) {
      const redirection = this.redirect;
      if (username && password) {
        this.login({ username, password, redirection });
      }
    },
  },
};
</script>

<style></style>
