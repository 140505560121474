import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";

export const validationService = {
  validateDocument,
  validateVolume,
  validateDocumentEU,
};

const validationEndpoint = KUrls.VALIDATION_ENDPOINT || "/validate";

async function validateDocument(companySchema, documentId) {
  const authorization = authHeader();
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${validationEndpoint}/${companySchema}/documents`,
    {
      documentId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
  const data = { ...response.data };

  return data;
}

async function validateDocumentEU(companySchema, documentId, isAltDoc) {
  const authorization = authHeader();
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${validationEndpoint}/${companySchema}/documentsEU`,
    {
      docHistId: documentId,
      alt: isAltDoc,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
  const data = response.data;

  return data;
}

async function validateVolume(companySchema, volumeId) {
  const authorization = authHeader();
  const response = await axios.post(
    `${KUrls.BASE_REST_URL}${validationEndpoint}/${companySchema}/volumes`,
    {
      volumeId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
  const data = { ...response.data };
  return data;
}
