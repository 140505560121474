import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import PageNotFound from "../views/PageNotFound";
// import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    redirect: { name: "Login" },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "Welcome",
        component: () => import("../views/Welcome.vue"),
      },
      {
        path: "configuration",
        component: () =>
          import(
            /* webpackChunkName: "configuration" */ "../views/Configuration.vue"
          ),
      },
      {
        path: ":company/class_documents",
        component: () =>
          import(
            /* webpackChunkName: "documents" */ "../views/ClassDocSearch.vue"
          ),
      },
      {
        path: ":company/class_search",
        component: () =>
          import(
            /* webpackChunkName: "documentHistory" */ "../views/ClassDocSearch.vue"
          ),
      },
      {
        path: ":company/class_einvoice_details",
        component: () =>
          import(
            /* webpackChunkName: "EInvoiceSearch" */ "../views/EInvoiceSearch.vue"
          ),
      },
      {
        path: ":company/class_upload",
        component: () =>
          import(/* webpackChunkName: "Upload" */ "../views/Upload.vue"),
      },
      {
        path: ":company/class_signature",
        component: () =>
          import(/* webpackChunkName: "Sign" */ "../views/Sign.vue"),
      },
      {
        path: ":company/change_pec_password",
        component: () =>
          import(
            /* webpackChunkName: "ChangePECPassword" */ "../views/ChangePECPassword.vue"
          ),
      },
      {
        path: ":company/signature",
        component: () =>
          import(/* webpackChunkName: "MultiSign" */ "../views/MultiSign.vue"),
      },
      {
        path: ":company/einvoice_status",
        component: () =>
          import(
            /* webpackChunkName: "EinvoiceStatus" */ "../views/EinvoiceStatus.vue"
          ),
      },
      {
        path: ":company/volumes_working",
        component: () =>
          import(/* webpackChunkName: "Volumes" */ "../views/Volumes.vue"),
      },
      {
        path: ":company/volumes_signed",
        component: () =>
          import(/* webpackChunkName: "Volumes" */ "../views/Volumes.vue"),
      },
      {
        path: "rc_signature",
        component: () =>
          import(/* webpackChunkName: "RC_Sign" */ "../views/Sign.vue"),
      },
      {
        path: ":company/search",
        component: () =>
          import(
            /* webpackChunkName: "GlobalSearch" */ "../views/GlobalSearch.vue"
          ),
      },
      {
        path: ":company/pdf_template",
        component: () =>
          import(
            /* webpackChunkName: "TemplateSearch" */ "../views/policies/TemplateSearch.vue"
          ),
      },
      {
        path: ":company/pdf_utils",
        component: () =>
          import(
            /* webpackChunkName: "TrimPdf" */ "../views/policies/TrimPdf.vue"
          ),
      },
      {
        path: ":company/class_policy_upload",
        component: () =>
          import(
            /* webpackChunkName: "UploadPolicy" */ "../views/policies/UploadPolicy.vue"
          ),
      },
      {
        path: ":company/policy_search",
        component: () =>
          import(
            /* webpackChunkName: "PolicySearch" */ "../views/policies/PolicySearch.vue"
          ),
      },
      {
        path: ":company/rao_new_user",
        component: () =>
          import(
            /* webpackChunkName: "PolicySearch" */ "../views/rao/NewUser.vue"
          ),
      },
      {
        path: ":company/rao_search_user",
        component: () =>
          import(
            /* webpackChunkName: "PolicySearch" */ "../views/rao/UserSearch.vue"
          ),
      },
      {
        path: ":company/policy_settings",
        component: () =>
          import(
            /* webpackChunkName: "PolicySearch" */ "../views/policies/Settings.vue"
          ),
      },
      {
        // generic path
        path: "*",
        component: PageNotFound,
      },
    ],
  },
  {
    path: "/validation",
    name: "Validation",
    component: () => import("../views/TESTROUTE.vue"),
  },
  {
    path: "/document-detail/:classId/:documentId",
    name: "DocumentDetail",
    component: () =>
      import(
        /* webpackChunkName: "documentDetail" */ "../components/DocumentDetail.vue"
      ),
    props: true,
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login", "/register", "/validation"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next({
      path: "/login",
      // query: { redirect: to.path },
    });
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.parent.name === 'Dashboard')) {
//     store.state.menu.data.classes
//   }
//   console.log(to);
//   next();
// });

export default router;
