import merge from "lodash/merge";
// import config from "../../config";

// const confUrls = config.properties["urls"];

const KUrls = {
  DOMAIN: "http://localhost:8080",
  BASE_REST_URL: "/EurotextPortal/rest",
  BASE_SERVLET_URL: "/EurotextPortal/servlets",
  AUTHENTICATION_ENDPOINT: "/authenticate",
  INPUT_ENDOPOINT: "/input",
  CLASS_ENDPOINT: "/class",
  DOWNLOAD_ENDPOINT: "/download",
  VALIDATION_ENDPOINT: "/validate",
  VOLUME_ENDPOINT: "/volumes",
  DOSSIER_ENDPOINT: "/dossiers",
  EINVOICE_ENDPOINT: "/einvoices",
  WEB_SOCKET_ENDPOINT: "ws://localhost:8080/EurotextPortal/wsock",
  MASSIVE_SIGN_SERVICE: "/MassiveSignService",
  VOLUME_SERVICE: "/VolumeService",
  routes: {
    SEARCH: "class_search",
    DOCUMENTS: "class_documents",
    EINVOICE_DETAILS: "class_einvoice_details",
    EINVOICE_STATUS: "class_einvoice_status",
    UPLOAD: "class_upload",
    SIGNED_VOLUMES: "volumes_signed",
    WORKING_VOLUMES: "volumes_working",
    RC_SIGNATURE: "rc_signature",
  },
};

fetch(process.env.BASE_URL + "config.json")
  .then((response) => response.json())
  .then((config) => {
    merge(KUrls, config.urls);
  });

// console.log("urls", config.properties);

// const KUrls = merge(defaultUrls, confUrls);

export { KUrls };
