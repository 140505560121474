import UAParser from "ua-parser-js";
const UA = new UAParser();
export const uaMixin = {
  data() {
    return {
      isMobile: null,
      isIOS: UA.getOS().name === "iOS",
    };
  },
  methods: {
    checkUA() {
      switch (UA.getDevice().type) {
        case "mobile":
        case "tablet":
          this.isMobile = true;
          break;
        default:
          this.isMobile = false;
      }
    },
  },
  created() {
    this.checkUA();
  },
};
