import axios from "axios";
// import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";
import interceptors, { showGenericError } from "./interceptors";
import { to2DecimalFloat } from "../helpers";
import { toStringISODate } from "../helpers/date";

function handleManagedResponseError(error) {
  if (error.response) {
    if (error.response.status === 550) {
      return;
    }
  }
  showGenericError();
}

const instance = axios.create({
  useGlobalInterceptors: true,
  // baseURL: KUrls.DOMAIN,
});
instance.interceptors.request.use(
  interceptors.dumpRequest,
  interceptors.dumpError
);
instance.interceptors.response.use(
  interceptors.dumpResponse,
  interceptors.dumpError
);
instance.interceptors.request.use(interceptors.setAuthToken);
// instance.interceptors.response.use(null, interceptors.handleGenericError);
instance.interceptors.response.use(null, interceptors.handleUnauthorized);
// instance.interceptors.response.use(null, interceptors.handleCustom550);
// console.log("global interceptors", interceptors.global);
// console.log("instance interceptors", inst.interceptors);

// export const policiesService = {
//   // responseSchemas,
//   loadConfig,
//   fetchRoles,
//   fetchUserMerchants,
//   fetchUsersByRole,
//   fetchUserInfo,
//   fetchTemplateGroups,
//   fetchTemplates,
//   fetchTemplateMetadata,
//   fetchTemplateSamplePdf,
//   createTemplateGroup,
//   deleteTemplateGroup,
//   createTemplate,
//   associateTemplatesToGroup,
//   finalizeGroup,
//   uploadPolicyFile,
//   uploadPolicyMetadata,
//   startUserNotification,
//   deleteUploadedDocuments,
//   mergePDFs,
//   splitPDF,
//   getPdfToDisplay,
//   customerClickPerformed,
//   searchPolicies,
//   displayDocument,
//   invalidatePolicy,
//   deletePolicy,
//   raoCreateUser,
//   raoUserExists,
//   fetchAllSettings,
//   initMailBox,
//   changeEmailPassword,
//   toggleUserClickRequired,
// };

let insuranceEndpoint = KUrls.INSURANCE_ENDPOINT || "/insurance";

export function loadConfig() {
  instance.defaults.baseURL = KUrls.DOMAIN;
  insuranceEndpoint = KUrls.INSURANCE_ENDPOINT || "/insurance";
}

export async function fetchRoles(company) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/roles`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error.toJSON());
    return Promise.reject(error);
  }
}

export async function fetchUserMerchants(company) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/merchants`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function fetchUsersByRole(
  company,
  roleName,
  merchantUniqueName,
  page,
  perPage,
  filters
) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/users`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          merchantUniqueName,
          roleName,
          page,
          pageResults: perPage,
          filters,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function fetchUserInfo(company, userSubNickname) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/documents/userinfo`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          subNickname: userSubNickname,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function fetchTemplateGroups(
  company,
  merchantUniqueName,
  visibility,
  isTemporary,
  page = 1,
  perPage = 50,
  folderType = null,
  filters = null
) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/groups`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          merchantUniqueName,
          visibility,
          isTemporary,
          page,
          pageResults: perPage,
          groupType: folderType,
          filters,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function fetchTemplates(
  company,
  merchantUniqueName,
  visibility,
  isTemporary,
  page = 1,
  perPage = 100,
  filters
) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/item`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          merchantUniqueName,
          visibility,
          isTemporary,
          page,
          pageResults: perPage,
          filters,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function fetchTemplateMetadata(company, templateId) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/itemmetadata`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          templateId,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function fetchTemplateSamplePdf(company, templateId) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/itemsample`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          templateId,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function createTemplateGroup(
  groupId = null,
  company,
  groupName,
  merchantUniqueName,
  isPublic,
  timeoutSignatureDays,
  pecAddress,
  folderType,
  payRemoteMerchant
) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/groups`,
      {
        groupId,
        groupName,
        merchantUniqueName,
        isPublic,
        timeoutSignatureDays,
        pecAddress,
        groupType: folderType,
        payMerchantUName: payRemoteMerchant,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function deleteTemplateGroup(
  company,
  groupName,
  merchantUniqueName
) {
  try {
    // const authorization = authHeader();
    const response = await instance.delete(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/groups?groupName=${groupName}&merchantUniqueName=${merchantUniqueName}`,
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
        data: {
          groupName,
          merchantUniqueName,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function createTemplate(
  templateId = null,
  company,
  templateName,
  merchantUniqueName,
  metadataInfo,
  samplePdfFile,
  isPublic
) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/item`,
      {
        templateId,
        name: templateName,
        merchantUniqueName,
        metadataInfo,
        samplePdfFile,
        isPublic,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function associateTemplatesToGroup(
  company,
  groupId,
  companyTemplateId,
  agencyTemplateId,
  customerTemplateId
) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/itemgroups`,
      {
        groupId,
        agencyTemplateId,
        companyTemplateId,
        customerTemplateId,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function finalizeGroup(
  company,
  groupId,
  groupName,
  isPublic,
  timeoutSignatureDays,
  pecAddress,
  folderType
) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/finalizegroup`,
      {
        groupId,
        groupName,
        isPublic,
        timeoutSignatureDays,
        pecAddress,
        groupType: folderType,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 * Uploads a policy
 */
export async function uploadPolicyFile(company, b64File, mime_type, sha256) {
  try {
    // TODO set axios default headers https://github.com/axios/axios#global-axios-defaults
    // const authorization = authHeader();
    // const kindOfInput = eurotextConfig.kindOfInput;
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/documents/upload`,
      {
        file: b64File,
        // kindOfInput,
        mime_type,
        hashSHA256HexIn: sha256,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    // const location = response.headers.location;
    // console.log(location);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    let ret = error;
    if (error.response) ret = error.response;
    return Promise.reject(ret);
  }
}

export async function uploadPolicyMetadata(
  company,
  className,
  merchantUniqueName,
  serverFilePath,
  docId,
  relatedDocId,
  folderId,
  templateId,
  templateType,
  agent,
  subAgent,
  customer,
  pec,
  timeoutSigDays,
  extId,
  additionalExtId = null,
  paymentDate = null,
  requirePayment,
  payMerchantUName = null
) {
  try {
    // const authorization = authHeader();
    // const kindOfInput = eurotextConfig.kindOfInput;
    console.log("requirePayment", requirePayment);
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/documents/metadata`,
      {
        className,
        merchantUniqueName,
        originalFile: serverFilePath,
        documentId: docId,
        relatedDocumentId: relatedDocId,
        groupId: folderId,
        templateId: templateId,
        templateType: templateType,
        subnicknameAgent: agent,
        subnicknameSubAgent: subAgent,
        subnicknameCustomer: customer,
        pecAddress: pec,
        timeoutDays: timeoutSigDays,
        externalIdentifier: extId,
        additionalExternalIdentifier: additionalExtId,
        paymentDate,
        paymentRequired: requirePayment,
        payMerchantUName: payMerchantUName,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    const data = response.data;
    console.log(data);
    return location;
  } catch (error) {
    console.error(error);
    let ret = error;
    if (error.response) ret = error.response;
    return Promise.reject(ret);
  }
}

/**
 *
 * @param {String} company
 * @param {String} relatedDocId
 * @param {String} paymentAmount
 * @param {String} paymentCurrency
 * @returns Promise with webservice response data
 */
export async function startUserNotification(
  company,
  relatedDocId,
  paymentAmount,
  paymentCurrency
) {
  try {
    // const authorization = authHeader();
    const amount = to2DecimalFloat(paymentAmount);
    console.log(amount);
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/documents/notify`,
      {
        relatedDocumentId: relatedDocId,
        currency: paymentCurrency,
        amount,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    let ret = error;
    if (error.response) ret = error.response;
    return Promise.reject(ret);
  }
}

export async function deleteUploadedDocuments(company, relatedDocId) {
  try {
    // const authorization = authHeader();
    const response = await instance.delete(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/documents/delete?relatedDocumentId=${relatedDocId}`,
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
        // data: {
        //   relatedDocumentId: relatedDocId,
        // },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    let ret = error;
    if (error.response) ret = error.response;
    return Promise.reject(ret);
  }
}

export async function mergePDFs(company, serverFileLocations, outputName) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/utility/merge`,
      {
        originalFiles: serverFileLocations,
        outputName,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function splitPDF(company, serverFileLocation, page, outputName) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/utility/split`,
      {
        originalFile: serverFileLocation,
        page,
        outputName,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function getPdfToDisplay(
  company,
  docId,
  fileType,
  isFinalized,
  secureString
) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/public/display`,
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          docId: docId,
          fileType,
          isFinalized,
          secureString,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function customerClickPerformed(company, docId, clickPerformed) {
  try {
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/public/userboxclick`,
      {
        docId: docId,
        clickPerformed,
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function searchPolicies(
  company,
  merchantUniqueName,
  page,
  perPage,
  filters
) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/search/globalsearch`,
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          merchantUniqueName,
          page,
          pageResults: perPage,
          filters,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function displayDocument(company, docId) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/search/display`,
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          docId,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function invalidatePolicy(company, policyId) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/search/invalidate`,
      {
        policyId,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function deletePolicy(company, policyId) {
  try {
    // const authorization = authHeader();
    const response = await instance.delete(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/search/delete?policyId=${policyId}`,
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 *
 * @param {String} company
 * @param {String|Number} policyId Id of the policy
 * @param {Date} paymentDate Selected date of payment
 * @returns A promise with the response data
 */
export async function setPolicyAsPaid(company, policyId, paymentDate) {
  try {
    const date = toStringISODate(paymentDate);
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/search/update/`,
      {
        policyId,
        paymentDate: date,
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function raoCreateUser(properties) {
  try {
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/rao/users`,
      {
        ...properties,
      },
      {
        headers: {
          //   "Content-Type": "application/json",
          // Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function raoUserExists(username) {
  try {
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/rao/users/exists`,
      {
        headers: {
          // "Content-Type": "application/json",
          // Authorization: authorization,
        },
        params: {
          nickname: username,
        },
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error.toJSON());
    return Promise.reject(error);
  }
}

export async function fetchAllSettings(company) {
  try {
    // const authorization = authHeader();
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/settings`,
      {}
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function initMailBox(
  company,
  emailUser,
  emailPassword,
  emailType
) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/settings/initmailbox`,
      {
        emailUser,
        emailPassword,
        emailType,
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function changeEmailPassword(
  company,
  oldPassword,
  newPassword,
  emailType
) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/settings/changemailboxpwd`,
      {
        oldPassword,
        newPassword,
        emailType,
      }
    );
    return response.data;
  } catch (error) {
    handleManagedResponseError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function toggleUserClickRequired(company, clickRequired) {
  try {
    // const authorization = authHeader();
    const response = await instance.post(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/settings/userclick`,
      {
        required: clickRequired,
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

// const responseSchemas = Object.freeze({
//   fetchAllSettings: {
//     clickRequired: "signature_click_required",
//     mailboxes: "mailboxes",
//   },
// });

export async function getRemoteMerchants(company) {
  try {
    const response = await instance.get(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/paymerchants`
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}

export async function updateTGroupPayMerchant(
  company,
  groupName,
  payMerchantNickname
) {
  try {
    const response = await instance.patch(
      `${KUrls.BASE_REST_URL}${insuranceEndpoint}/${company}/templates/groups/update`,
      {
        folderName: groupName,
        payMerchantNickname: payMerchantNickname,
      }
    );
    return response.data;
  } catch (error) {
    showGenericError();
    console.error(error);
    return Promise.reject(error);
  }
}
