const config = {
  bordered: false,
  hoverable: true,
  narrowed: true,
  scrollable: true,
  striped: true,
  mobileCards: true,
};

export { config as tableConfig };
