export const loginResponse = Object.freeze({
  token: "token",
  userSchemas: "userSchemas",
  username: "username",
  merchant: "merchant",
  subNickname: "subNickname",
});

export const fetchInvoicesResponseSchema = Object.freeze({
  einv: "einv",
  page: "page",
  totalPages: "totalPages",
  totalResults: "totalResults",
});

export const fetchDocumentsResponseSchema = Object.freeze({
  documents: "documents",
  page: "page",
  totalPages: "total_pages",
  totalResults: "total_results",
});

export const fetchWorkingVolumesResponseSchema = Object.freeze({
  volumes: "volumes",
  page: "page",
  totalPages: "total_pages",
  totalResults: "total_results",
});

export const fetchWorkingVolumesDocumentsResponseSchema = Object.freeze({
  documents: "documents",
  page: "page",
  totalPages: "total_pages",
  totalResults: "total_results",
});

export const ValidationResponseSchema = Object.freeze({
  timestamp: "timestamp",
  signatures: "signatures",
  success: "success",
});

export const errorResponseSchema = Object.freeze({
  code: "code",
  message: "message",
  internalMessage: "internalMessage",
  moreInfo: "moreInfo",
  error: "error",
});

export const responseSchemas = Object.freeze({
  fetchAllSettings: {
    clickRequired: "signature_click_required",
    isPay: "is_pay",
    mailboxes: "mailboxes",
    uniqueName: "unique_name",
    externalName: "external_name",
    id: "id",
  },
});
