import axios from "axios";
import { authHeader } from "@/helpers/authHeader.js";
import { KUrls } from "@/helpers/constants";

export const userService = {
  fetchMenu,
  updateUserPassword,
  updateOfficePECPassword,
  getOfficesPEC,
};

const userEndpoint = "/user";

function getAuthHeader() {
  const authorization = authHeader();
  return authorization;
}

function handleResponse(response) {
  const data = response && response.data;
  if (!response.status && response.status != 200) {
    if (response.status === 401) {
      // unauthorized
    }
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }

  return data;
}

async function fetchMenu() {
  try {
    const authorization = getAuthHeader();
    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${userEndpoint}/menu`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function updateUserPassword(oldPassword, newPassword) {
  try {
    const authorization = getAuthHeader();
    const response = await axios.put(
      `${KUrls.BASE_REST_URL}${userEndpoint}/password`,
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    if (response.status !== 204) {
      return false;
    } else {
      // usually it's 204 No-Content
      return true;
    }
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function updateOfficePECPassword(company, officeId, newPassword) {
  try {
    const authorization = getAuthHeader();
    const response = await axios.put(
      `${KUrls.BASE_REST_URL}${userEndpoint}/pec/${company}/password`,
      {
        officeId,
        newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    if (response.status !== 204) {
      return false;
    } else {
      // usually it's 204 No-Content
      return true;
    }
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

async function getOfficesPEC(company) {
  try {
    const authorization = getAuthHeader();
    const response = await axios.get(
      `${KUrls.BASE_REST_URL}${userEndpoint}/pec/${company}/offices`,
      {
        headers: {
          // "Content-Type": "application/json",
          Authorization: authorization,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}
